<app-app-holder>
    <app-navbar></app-navbar>

    <app-sidebar>
        <app-settings-subnav></app-settings-subnav>
    </app-sidebar>

    <app-content>
        <div class="app__title">
            <h1>Application info</h1>
        </div>

        <div class="table__overflow">
            <table
                class="table-view m-b-4"
                *ngIf="!isLoading">
                <tr>
                    <th colspan="2">API information</th>
                </tr>
                <tr>
                    <td class="td-small">
                        <strong>Application version:</strong>
                    </td>
                    <td>
                        {{ version }}
                        <small *ngIf="environment.environment === 'development'">(version number is not accurate when in development)</small>
                    </td>
                </tr>
                <tr>
                    <td class="td-small"><strong>Environment:</strong></td>
                    <td>{{ environment.environment }}</td>
                </tr>
                <tr>
                    <td class="td-small"><strong>Default API URL:</strong></td>
                    <td>
                        <app-pill-url
                            [showUrl]="true"
                            [websiteUrl]="apiUrl"></app-pill-url>
                    </td>
                </tr>
                <tr>
                    <th colspan="2">Support</th>
                </tr>
                <tr>
                    <td class="td-small"><strong>Email:</strong></td>
                    <td>
                        <app-pill-mailto [mailtoEmail]="'contact@alpinedigital.be'"></app-pill-mailto>
                    </td>
                </tr>
                <tr>
                    <td class="td-small"><strong>Phonenumber:</strong></td>
                    <td>
                        <app-pill-telephone [contactPhonenumber]="'+32 479 64 97 60'"></app-pill-telephone>
                    </td>
                </tr>
                <tr>
                    <td class="td-small"><strong>Website:</strong></td>
                    <td>
                        <app-pill-url
                            [showUrl]="true"
                            [websiteUrl]="'https://www.alpinedigital.be'"></app-pill-url>
                    </td>
                </tr>
            </table>

            <app-loading-message
                *ngIf="isLoading"
                class="col-sm-9"></app-loading-message>
        </div>

        <dl class="row">
            <!--<dt class="col-sm-12 m-t-3"><h3>Social info</h3></dt>
            <dt class="col-sm-3"><h4>Whatsapp:</h4></dt>
            <dd class="col-sm-9"><app-pill-whatsapp [phoneNumber]="'+32479649760'"></app-pill-whatsapp></dd>
            <dt class="col-sm-3"><h4>LinkedIn:</h4></dt>
            <dd class="col-sm-9"><app-pill-linkedin [linkedinUrl]="'https://www.linkedin.com/company/alpinedigital-be'"></app-pill-linkedin></dd>
            <dt class="col-sm-3"><h4>Facebook:</h4></dt>
            <dd class="col-sm-9"><app-pill-facebook [facebookUrl]="'https://www.facebook.com/alpinedigital.be'"></app-pill-facebook></dd>
            <dt class="col-sm-3"><h4>Instagram:</h4></dt>
            <dd class="col-sm-9"><app-pill-instagram [instagramUrl]="'https://www.instagram.com/alpinedigital_be/'"></app-pill-instagram></dd>-->

            <dt class="col-sm-12 text-center"><h2>Latest changes</h2></dt>
            <ul class="text-center w-100">
                <li class="m-t-5"><h3>1.0.176</h3></li>
                <li>Fix for timesheet sync to Jira</li>
                <li>Fix for Add Project</li>
                <li>Tokens are now valid for a longer time</li>
                <li class="m-t-5"><h3>1.0.127</h3></li>
                <li>Add educations and skills to a resumé fixed</li>
                <li>Tags can now be added in bulk in the contacts overview</li>
                <li>A new filter tag input has appeared!</li>
                <li>Contacts can now be filtered by tags using the new filter tag input</li>
                <li class="m-t-5"><h3>1.0.40</h3></li>
                <li>Add partner bug fixed</li>
                <li>Timesheets now show extra valuable information (hours and pricing)</li>
                <li>Timesheets can contain comments now</li>
                <li>Timesheets required hours (days) added</li>
                <li class="m-t-5"><h3>1.0.39</h3></li>
                <li>Archived deal can now be de-archived</li>
                <li>Unessecary mailing removed</li>
                <li class="m-t-5"><h3>1.0.38</h3></li>
                <li>Default autocomplete was removed from already auto completed fields</li>
                <li>Minor bugfixes for comments and timesheets</li>
                <li>Stock update history timing being off 1 hour is now fixed</li>
                <li>Employee birthday and hire date reminders are only sent if there are upcoming birthdays/hire dates</li>
                <li>PDF pill now allows you to view the pdf in a new window</li>
                <li>QRcode PDF in productdetail modal also has an updated styling now</li>
                <li>QR stock update page has been expanded: EAN/SKU and warehouselocation are now shown</li>
                <li>Roles and permissions cleanup (more user friendly now)</li>
                <li class="m-t-5"><h3>1.0.30</h3></li>
                <li>Minor inventory updates</li>
                <li>User permissions updated</li>
                <li class="m-t-5"><h3>1.0.23</h3></li>
                <li>Textareas resizing has been limited to only resize vertically with a fixed height</li>
                <li>Description and extra info fields now display the linebreaks correctly as inputted</li>
                <li>It has now been made clear whether prices are excluding VAT</li>
                <li>
                    Fixed a bug where data couldn't load properly in dropdown fields (ex: parent/child lists (products module) - role assginments (settings
                    module))
                </li>
                <li class="m-t-5"><h3>1.0.22</h3></li>
                <li>Product categories were added</li>
                <li class="m-t-5"><h3>1.0.21</h3></li>
                <li>QR-codes PDF download now combines more qrcodes per page</li>
                <li>Bug where adding child products would only be recognised after transaction fixed</li>
                <li>Timesheets total amount of hours can also be viewed in days now</li>
                <li class="m-t-5"><h3>1.0.20</h3></li>
                <li>While viewing a product detail modal, we can now see if there is a sufficient amount of assigned children products in stock</li>
                <li>The material amount state values, viewable in the stock-history, are renamed to 'ADDED' and 'REMOVED'</li>
                <li class="m-t-5"><h3>1.0.19</h3></li>
                <li>Removed unnecessary unit measurement field on the 'product add' screen</li>
                <li class="m-t-5"><h3>1.0.18</h3></li>
                <li>Stable release for the refactored inventory module</li>
                <li>Global tags are now more reliable</li>
                <li class="m-t-5"><h3>1.0.17</h3></li>
                <li>Refactoring of the add product modal</li>
                <li>Overall UX/UI updates for inventory</li>
                <li class="m-t-5"><h3>1.0.16</h3></li>
                <li>Some major bugfixes for the inventory module</li>
                <li>Product modals are bigger now for better readability</li>
                <li>Material links cleanup</li>
                <li class="m-t-5"><h3>1.0.15</h3></li>
                <li>Parent and child product can be linked in the product details</li>
                <li>Parent product can now be linked while creating a new product</li>
                <li class="m-t-5"><h3>1.0.14</h3></li>
                <li>Anonymous users are no longer able to create companies when filling in their timesheets (even with their token)</li>
                <li>Timesheets module got major updates and is now stable for production</li>
                <li class="m-t-5"><h3>1.0.13</h3></li>
                <li>Unit is renamed to measurement unit</li>
                <li>Child products can now be linked while creating a new product</li>
                <li class="m-t-5"><h3>1.0.12</h3></li>
                <li>Add/edit product modal fields got rearranged</li>
                <li>Products now accepts a longer title</li>
                <li>Products can no longer be deleted (only archived)</li>
                <li class="m-t-5"><h3>1.0.11</h3></li>
                <li>Stock history is now implemented for products</li>
                <li>QR-codes are working again</li>
                <li class="m-t-5"><h3>1.0.10</h3></li>
                <li>Overall large changes to the inventory module</li>
                <li>Materials and products are now combined into one object</li>
                <li class="m-t-5"><h3>1.0.9</h3></li>
                <li>Material stock history now shows if a product has been deleted (this was previously unclear)</li>
                <li>Contacts can now be linked to projects</li>
                <li>Mailing setup now has a dedicated page</li>
                <li>Added reminder mail for timesheets</li>
                <li>Added mails for CRM deals that will expire</li>
                <li class="m-t-5"><h3>1.0.8</h3></li>
                <li>Performance and reliability update for linking employees to other objects</li>
                <li>Bug where deals in the CRM module would not be updated fixed</li>
                <li>You are now able to see the exact amount of days until a deal will be expired</li>
                <li>CRM deal reason of loss now has a maximum length of 250 characters</li>
                <li>Bug where linking products and materials would not work properly fixed</li>
                <li>Mailing is now customisable (Settings -> company settings)</li>
                <li class="m-t-5"><h3>1.0.7</h3></li>
                <li>CRM warning widget was added</li>
                <li>There is now a pill for Comanage offers</li>
                <li>Customers can now be added to project in the add and edit project modal</li>
                <li>Project link between employees and products has been updated</li>
                <li>Permissions for timesheets have been updated</li>
                <li class="m-t-5"><h3>1.0.6</h3></li>
                <li>Adding timesheets is much more userfriendly</li>
                <li>Timesheets can be removed now</li>
                <li>Customers can now be linked with projects and deals inside the customer details</li>
                <li>Add, update and remove customer are fully integrated</li>
                <li>Customer widget was created</li>
                <li class="m-t-5"><h3>1.0.5</h3></li>
                <li>Some modal sizes have been increased for readability</li>
                <li>Linking company with projects and contacts has been updated</li>
                <li>CRM overview has been updated</li>
                <li class="m-t-5"><h3>1.0.4</h3></li>
                <li>A deal entering a winning lane will prompt the user to convert it to a project</li>
                <li>A customer can be created while converting a lead to a deal</li>
                <li>The user will have the option to archive e lead on converting it to a deal</li>
                <li>A customer can be linked to a deal</li>
                <li>A lead can now be converted to a deal</li>
                <li class="m-t-5"><h3>1.0.3</h3></li>
                <li>Customer module is now available</li>
            </ul>
        </dl>
    </app-content>
</app-app-holder>
