import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { Timesheet } from 'src/app/models/Timesheet';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/dialog.component';
import { JiraLoginService } from 'src/app/shared/login-components/jira-login/jira-login.service';

import { AddTimesheetModalComponent } from '../add-timesheet-modal/add-timesheet-modal.component';
import { ChangeSelectedDate, FetchTimesheets } from '../timesheets.actions';
import { TimesheetState } from '../timesheets.state';

@Component({
    selector: 'app-timesheets-overview',
    templateUrl: './timesheets-overview.component.html',
    styleUrls: ['./timesheets-overview.component.scss'],
    providers: [DatePipe],
})
export class TimesheetsOverviewComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    @Select(TimesheetState.selectedDate) selectedDate$: Observable<string>;
    @Select(TimesheetState.timesheets) timesheets$: Observable<Timesheet[]>;

    selectedDate: string;
    currentDate: string;
    isLoading = true;
    employees = [];
    sortedByEmployee = true;
    sortedByProject = false;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private logger: NGXLogger,
        private jiraLoginService: JiraLoginService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
    ) {}

    ngOnInit(): void {
        this.currentDate = moment().format('MMMM yyyy');
        this.logger.debug('Fetching timesheets');
        this.subscriptions.add(
            this.selectedDate$.subscribe((s) => {
                this.selectedDate = s;
            }),
        );

        this.store.dispatch(new FetchTimesheets());

        this.subscriptions.add(
            this.timesheets$.subscribe((timesheets) => {
                if (timesheets) {
                    this.employees = [];
                    timesheets.forEach((el) => this.employees.push(el.employee));
                    if (
                        timesheets.every(
                            (t) => moment(new Date(t.expirationDate)).format('YYYY-MM') == moment(new Date(this.selectedDate)).format('YYYY-MM'),
                        ) ||
                        timesheets.length == 0
                    ) {
                        this.isLoading = false;
                    }
                }
            }),
        );
    }

    byEmployeeSelected() {
        this.sortedByEmployee = true;
        this.sortedByProject = false;
    }

    byProjectSelected() {
        this.sortedByEmployee = false;
        this.sortedByProject = true;
    }

    moveDate(amount: number) {
        this.isLoading = true;
        this.logger.debug('Changing selected date & refetch timesheets');
        this.subscriptions.add(
            this.store.dispatch(new ChangeSelectedDate(amount)).subscribe(() => {
                this.store.dispatch(new FetchTimesheets());
            }),
        );
    }

    addNewTimesheet() {
        this.logger.debug('Opening addTimesheetModal');
        const modalRef = this.modalService.open(AddTimesheetModalComponent, {
            windowClass: 'modal-prompt',
            animation: false,
        });
        modalRef.componentInstance.currentEmployees = this.employees;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    importFromJiraForSelectedMonth() {
        const selectedDateSplit = this.selectedDate.split('-');
        const year = parseInt(selectedDateSplit[0]);
        const month = parseInt(selectedDateSplit[1]);
        const date = new Date(this.selectedDate);
        const formattedDate = this.datePipe.transform(date, 'MMMM yyyy');

        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    title: 'Import Timesheets from Jira',
                    question: `<br>Are you sure you want to import all timesheets from Jira for <strong>${formattedDate}</strong>?<br><br>
                    All timesheets for linked employees and projects will be updated<br>`,
                },
            })
            .afterClosed()
            .subscribe((result: boolean) => {
                if (result === true) {
                    this.jiraLoginService.importAllTempoTimeSheets(month, year).subscribe(
                        (response) => {
                            this.logger.debug('Successfully synchronized with Jira');
                            this.toastr.success('Successfully synchronized with Jira.');

                            this.store.dispatch(new FetchTimesheets());
                        },
                        (error) => {
                            this.logger.error('Failed to sync with Jira');
                            this.toastr.error('Oops, something went wrong. Please try again later.');
                        },
                    );
                }
            });
    }

    refresh() {
        this.isLoading = true;
        this.store.dispatch(new FetchTimesheets());
    }
}
